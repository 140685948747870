/*import $ from 'jquery';
 
 jQuery(document).on('click', '.bt-menu-trigger, .bt-menu-overlay', function () {
 jQuery('body').toggleClass('bt-menu-open');
 return false;
 });
 
 jQuery(document).on('click', '.menu-item-has-children > a .fa', function () {
 jQuery(this).closest('.menu-item-has-children').toggleClass('active');
 // return false;
 });
 
 jQuery(document).on('click', '.menu-item-accordeon', function (e) {
 e.preventDefault();
 
 jQuery(this).closest('li').toggleClass('open');
 });
 
 jQuery(document).ready(function () {
 var currentUrl = window.location.href;
 
 if (jQuery('.nav--menu a[href="' + currentUrl + '"]').length) {
 jQuery('.nav--menu a[href="' + currentUrl + '"]').closest('li').addClass('current-menu-item');
 jQuery('.nav--menu a[href="' + currentUrl + '"]').parents('li:not(.current-menu-item)').addClass('current-menu-ancestor');
 } else {
 if (jQuery('body').hasClass('page-product')) {
 var category_id = jQuery('input[name="id_category"]').val();
 
 
 jQuery('.nav--menu #category-' + category_id).addClass('current-menu-item');
 jQuery('.nav--menu #category-' + category_id).parents('li:not(.current-menu-item)').addClass('current-menu-ancestor');
 }
 }
 });*/

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { gsap, Power2 } from 'gsap';

jQuery(document).ready(function () {
    const menu = jQuery('.bt-menu');
    const menuToggle = jQuery('.bt-menu-trigger');
    const menuOverlay = jQuery('.bt-menu-overlay');
    var currentUrl = window.location.href;

    if (jQuery('.menu-box a[href="' + currentUrl + '"]').length) {
        jQuery('.menu-box a[href="' + currentUrl + '"]').closest('li').addClass('current-menu-item');
        jQuery('.menu-box a[href="' + currentUrl + '"]').parents('li:not(.current-menu-item)').addClass('current-menu-ancestor');
    } else {
        if (jQuery('body').hasClass('page-product')) {
            var category_id = jQuery('input[name="id_category"]').val();

            jQuery('.menu-box #category-' + category_id).addClass('current-menu-item');
            jQuery('.menu-box #category-' + category_id).parents('li:not(.current-menu-item)').addClass('current-menu-ancestor');
        }
    }

    jQuery('.layout-head-lowernav .menu-item-0').each(function () {
        jQuery(this).find('> .sub-menu').append('<div class="mega-menu-visuels"></div>');

        jQuery(this).find('.menu-item-visuel').each(function () {
            var id = jQuery(this).closest('li').attr('id');
            jQuery(this).closest('li').addClass('menu-item-has-visuel');
            jQuery(this).attr('data-id', id).appendTo(jQuery(this).closest('.menu-item-0').find('.mega-menu-visuels'));
        });
    });

    // Toggle menu on button click
    menuToggle.on("click", function () {
        if (jQuery('body').hasClass('bt-menu-open')) {
            closeMenu();
        } else {
            openMenu();
        }
    });
    menuOverlay.on("click", function () {
        closeMenu();
    });

    // Close menu when clicking outside
    jQuery(document).on("click touchstart", function (event) {
        if (!jQuery(event.target).closest('.bt-menu, .bt-menu-trigger').length) {
            closeMenu();
        }
    });

    // Function to open the menu
    function openMenu() {
        jQuery('body').addClass('bt-menu-open');
        // Use GSAP to animate the menu content with stagger effect
        gsap.fromTo(
                menu.find(".menu-box"),
                {
                    opacity: 0,
                    y: 20
                },
                {
                    opacity: 1,
                    y: 0,
                    stagger: 0.15,
                    duration: 0.5,
                    ease: "power2.out"
                }
        );
    }

    // Function to close the menu
    function closeMenu() {
        jQuery('body').removeClass('bt-menu-open');
    }

    jQuery('.menu-item-has-children > ul').each(function () {
        // jQuery(this).wrapInner('<div class="limiter cf"><div class="limiter-inner cf"><div class="sub-menu-ul cf"></div></div></div>');
    });
});

// bt-menu sub-menu
jQuery(document).on('click', '.menu-item-has-children > .licon-toggle', function () {
    jQuery(this).closest('.menu-item-has-children').toggleClass('opened');
    // return false;
});

// Hover effect
jQuery(document).on('mouseenter', '.menu-item-has-children', function () {
    jQuery('.menu-item-has-children.opened').removeClass('hovering');
    jQuery(this).addClass('hovering');
});
jQuery(document).on('mouseleave', '.menu-item-has-children', function () {
    jQuery(this).removeClass('hovering');
});

jQuery(document).on('mouseleave', '.sub-menu', function () {
    jQuery(this).closest('.menu-item-has-children').removeClass('hovering');
});

jQuery(document).on('mouseenter', '.menu-item-has-visuel', function () {
    var id = jQuery(this).closest('li').attr('id');
    jQuery(this).closest('.menu-item-0').find('.menu-item-visuel').removeClass('active');
    jQuery(this).closest('.menu-item-0').find('[data-id="' + id + '"]').addClass('active'); 
});


/*jQuery('body').append('<div class="bt-theme-selector cf">\n\
 <a href="javascript:;">\n\
 <i class="licon licon-sun"></i>\n\
 <i class="licon licon-moon"></i>\n\
 </a>\n\
 </div>');
 
 jQuery(document).on('click', '.bt-theme-selector a', function () {
 if (jQuery('body').attr('data-theme') === 'dark') {
 jQuery('body').attr('data-theme', '');
 jQuery(this).find('.text').html('Nuit');
 document.cookie = "theme=";
 
 } else {
 jQuery('body').attr('data-theme', 'dark');
 jQuery(this).find('.text').html('Jour');
 document.cookie = "theme=dark";
 }
 });*/