import $ from 'jquery';  
window.jQuery = $; window.$ = $;

jQuery(document).on('click', '.copy-to-clipboard', function (e) {
    e.preventDefault();
    var copyLink = $(this).attr('href');
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(copyLink).select();
    document.execCommand("copy");
    $temp.remove();

    jQuery(this).find('.copy-info').addClass('show');
    setTimeout(function () {
        jQuery('.copy-info.show').removeClass('show');
    }, 1000);

    return false;
});