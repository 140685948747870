import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import prestashop from 'prestashop';
import Swiper from 'swiper/bundle';



document.addEventListener('DOMContentLoaded', function () {
    doSwipers();
    
    prestashop.on('updatedProduct', (event) => {
        doSwipers();
    });
});

function doSwipers() {
    jQuery('.BC_swiper').each(function () {
        var BC_swiper = jQuery(this);
        var swiperContainer = BC_swiper.find('.swiper')[0];

        var swiper_args = {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 700,
            simulateTouch: false,
            watchOverflow: true,
            navigation: {
                prevEl: BC_swiper.find('.swiper-button-prev')[0],
                nextEl: BC_swiper.find('.swiper-button-next')[0],
            },
            pagination: {
                el: BC_swiper.find('.swiper-pagination')[0],
                clickable: true,
            },
            breakpoints: {
                768: {
                },
                1025: {
                },
            },
            on: {
                slideChange: function () {
                    BC_swiper.attr('data-index', this.activeIndex);
                },
                slideChangeTransitionStart: function () {
                    var futureActiveSlide = this.slides[this.activeIndex];
                    if (futureActiveSlide) {
                        futureActiveSlide.classList.add('loading');
                    }
                },
                slideChangeTransitionEnd: function () {
                    var previousActiveSlide = this.slides[this.activeIndex];
                    if (previousActiveSlide) {
                        previousActiveSlide.classList.remove('loading');
                    }
                }
            }
        };

        if (BC_swiper.data('spv')) {
            swiper_args.slidesPerView = BC_swiper.data('spv');
        }
        if (BC_swiper.data('spv_sm')) {
            swiper_args.breakpoints[768].slidesPerView = BC_swiper.data('spv_sm');
        }
        if (BC_swiper.data('spv_md')) {
            swiper_args.breakpoints[1025].slidesPerView = BC_swiper.data('spv_md');
        }
        if (BC_swiper.data('space')) {
            swiper_args.spaceBetween = BC_swiper.data('space');
        }
        if (BC_swiper.data('speed')) {
            swiper_args.speed = BC_swiper.data('speed');
        }
        if (BC_swiper.data('delay')) {
            swiper_args.autoplay = {
                delay: BC_swiper.data('delay'),
                disableOnInteraction: false,
            };
        }
        if (BC_swiper.hasClass('-no-gutters')) {
            swiper_args.spaceBetween = 0;
        }
        if (BC_swiper.hasClass('-no-loop')) {
            swiper_args.loop = false;
        }
        if (BC_swiper.data('effect')) {
            swiper_args.effect = BC_swiper.data('effect');
        }

        if (BC_swiper.find('.swiper-thumbs').length) {

            var swiperThumbs = new Swiper(BC_swiper.find('.swiper-thumbs .swiper')[0], {
                slidesPerView: 4,
                spaceBetween: 12,
            });

            swiper_args.thumbs = {
                swiper: swiperThumbs
            };
            swiperContainer = BC_swiper.find('.swiper-gallery')[0];
        }

        var swiper = new Swiper(swiperContainer, swiper_args);
    });
}