jQuery(document).on('click', '[trigger-action]', function () {
    var doAction = jQuery(this).attr('trigger-action');

    if (doAction) {
        if (jQuery('body').attr('trigger-action') === doAction) {
            // Already done, then remove
            jQuery('body').attr('do-action', '');
        } else {
            // Perform
            jQuery('body').attr('do-action', 'action-' + doAction);
        }
    }
});
jQuery(document).on('click', '[trigger-action-close]', function () {
    jQuery('body').attr('do-action', '');
});

jQuery(document).on('click', '[click-class]', function () {
    var target = jQuery(this).attr('click-class').split('|')[0];
    var classList = jQuery(this).attr('click-class').split('|')[1].replace(/\[|\]/g, '').split(',');

    if (target === 'this') {
        jQuery(this).toggleClass(classList.join(' '));
    } else if (target.includes('this::')) {
        jQuery(this).find(target.replace('this::', '')).toggleClass(classList.join(' '));
    } else if (target === '::parent') {
        jQuery(this).closest(target).toggleClass(classList.join(' '));
    } else {
        jQuery(target).toggleClass(classList.join(' '));
    }
});

jQuery(document).on('click', '[do_action]', function(){
    var action = jQuery(this).attr('do_action');
    
    if(action === 'scrollto-product-tabs'){
        jQuery("html, body").animate({
            scrollTop: $('#product-tabs').offset().top - 60,
        }, 500);
    }
});