import $ from 'jquery';


jQuery(document).ready(function ($) {
    is_scrolling();
});

jQuery(window).on('scroll', function ($) {
    is_scrolling();
});


function is_scrolling() {
    if (jQuery(window).scrollTop() > 150) {
        jQuery('body').addClass('is-scrolling');
        jQuery('body').removeClass('is-top');
    } else {
        jQuery('body').removeClass('is-scrolling');
        jQuery('body').removeClass('scrolling-up');
        jQuery('body').addClass('is-top');
    }
}


var lastKnownScrollPosition = 0, scrollPosition = 0;
var ticking = false;


document.addEventListener('scroll', (e) => {
    scrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(() => {
            // console.log('last: ' + lastKnownScrollPosition + ' / current: ' + scrollPosition);

            if (scrollPosition === 0) {
                // Top
            } else if (lastKnownScrollPosition > scrollPosition) {
                // Scrolled UP 
                jQuery('body').addClass('scrolling-up');
            } else {
                // Scrolled DOWN
                jQuery('body').removeClass('scrolling-up');
            }

            lastKnownScrollPosition = scrollPosition;
            ticking = false;
        });
        ticking = true;
    }
});

