import $ from 'jquery';
window.jQuery = $;
window.$ = $;

function setBackgroundColor(dataColorAttribute, element) {
    // Create an image element
    var img = new Image();
    img.src = dataColorAttribute;
    // When the image is loaded
    img.onload = function () {
        // Create a canvas element
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        // Draw the image on the canvas
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        // Get the pixel data from the canvas
        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var pixels = imageData.data;
        // Calculate the average color
        var totalR = 0, totalG = 0, totalB = 0, count = 0;
        for (var i = 0; i < pixels.length; i += 4) {
            totalR += pixels[i];
            totalG += pixels[i + 1];
            totalB += pixels[i + 2];
            count++;
        }

        var avgR = Math.round(totalR / count);
        var avgG = Math.round(totalG / count);
        var avgB = Math.round(totalB / count);
        var hexColor = rgbToHex(avgR, avgG, avgB);

        element.animate({
            'background-color': hexColor,
        });
    };
}

jQuery(document).ready(function () {

    jQuery('[data-showroom-color]').each(function () {
        var dataColorAttribute = jQuery(this).attr('data-showroom-color');
        setBackgroundColor(dataColorAttribute, jQuery(this).find('.mask'));
    });
});


function rgbToHex(r, g, b) {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();
}

jQuery(document).on('click', '.showroom-trigger', function (e) {
    e.stopPropagation(); // Prevents the click event from bubbling up to the body

    if (jQuery(this).hasClass('active')) {
        jQuery(this).removeClass('active');
    } else {
        jQuery(this).parents('.BC_item').find('.active').removeClass('active');
        jQuery(this).addClass('active');
    }
});

jQuery(document).on('click', function (e) {
    var target = jQuery(e.target);
    // Check if the clicked element is not within the popup
    if (!target.closest('.showroom-product').length && !target.hasClass('showroom-trigger')) {
        // Close the popup
        jQuery('.showroom-trigger.active').removeClass('active');
    }
});