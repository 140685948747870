import $ from 'jquery';
window.jQuery = $;
window.$ = $;


jQuery(document).ready(function ($) {

    jQuery('form input, form textarea').focus(function () {
        jQuery(this).closest('label').addClass('hover');
    });
    jQuery('form input, form textarea').focusout(function () {
        if (jQuery(this).val() === '') {
            jQuery(this).closest('label').removeClass('hover');
        }
    });
    
    jQuery('.gformbuilderpro_form .checkbox_item_wp input.-has-checkbox + label').each(function(){
        jQuery(this).wrapInner('<span></span>');
    });
    
    jQuery('.mega-upload-files').each(function(){
        jQuery(this).append('<i class="licon licon-toggle"></i>');
    });
});


jQuery(document).on('click', '.js-select2 .option', function () {
    var data = jQuery(this)[0];

    // trigger option click
    if (jQuery(this).parents('.js-select2').hasClass('-no-change')) {

    } else {
        jQuery(this).parents('.js-select2').siblings('.js-select').find('option[value="' + data.dataset.value + '"]').prop('selected', true);
    }
    // show selected
    jQuery(this).parents('ul').find('.selected').removeClass('selected');
    jQuery(this).parents('li').addClass('selected');

    jQuery(this).parents('.js-select2').removeClass('open');
});

jQuery(document).on('click', '.mega-upload-files .licon-toggle', function(){
    jQuery(this).closest('.mega-upload-files').toggleClass('active');
});