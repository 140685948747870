/* eslint-disable */
import 'expose-loader?exposes=Tether!tether';
import 'bootstrap/dist/js/bootstrap.min';
import 'flexibility';
import 'bootstrap-touchspin';
// import 'jquery-touchswipe';
import './selectors';

import './responsive';
import './checkout';
import './customer';
import './listing';
import './product';
import './cart';
import './lucyan';

import prestashop from 'prestashop';
import EventEmitter from 'events';
import DropDown from './components/drop-down';
import Form from './components/form';
import ProductMinitature from './components/product-miniature';
import ProductSelect from './components/product-select';
import TopMenu from './components/top-menu';

import './lib/bootstrap-filestyle.min';
import './lib/jquery.scrollbox.min';
import './components/block-cart';
import $ from 'jquery';
/* eslint-enable */

// "inherit" EventEmitter
// eslint-disable-next-line
for (const i in EventEmitter.prototype) {
    prestashop[i] = EventEmitter.prototype[i];
}

$(document).ready(() => {
    const dropDownEl = $('.js-dropdown');
    const form = new Form();
    const topMenuEl = $('.js-top-menu ul[data-depth="0"]');
    const dropDown = new DropDown(dropDownEl);
    const topMenu = new TopMenu(topMenuEl);
    const productMinitature = new ProductMinitature();
    const productSelect = new ProductSelect();

    dropDown.init();
    form.init();
    topMenu.init();
    productMinitature.init();
    productSelect.init();
});


import './lucyan/ie';

import './lucyan/do_actions';
import './lucyan/scroll';
import './lucyan/create';
import './lucyan/copytoclipboard';

import './lucyan/menu';
import './lucyan/forms';

import './lucyan/swiper';
import './lucyan/showroom';
import './lucyan/animations';


jQuery(document).ready(function ($) {

    jQuery('.btn').each(function () {
        if (!jQuery(this).find('.text').length) {
            jQuery(this).wrapInner('<span class="text"></span>');
        }
        var text = jQuery(this).find('.text').text();
        jQuery(this).addClass('-has-ghosttext').append('<span class="ghosttext">' + text + '</span>');
    });
});

jQuery(function () {
    jQuery('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                jQuery('html,body').animate({
                    scrollTop: target.offset().top - 60
                }, 1000); //  - 60
                return false;
            }
        }
    });
});


/*jQuery(function () {
 jQuery('a[href*="#"]:not([href="#"])').click(function () {
 if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
 var target = jQuery(this.hash);
 target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
 if (target.length) {
 jQuery('html,body').animate({
 scrollTop: target.offset().top - 60
 }, 1000);
 return false;
 }
 }
 });
 });*/
