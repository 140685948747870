import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// GSap
import { gsap, Power1, Power2, Power3, Power4 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


// =============================================================================
// Bloc animation
// =============================================================================
jQuery(document).ready(function ($) {
    handleBloc();
    handleAnimation();

    $(window).on('scroll resize', handleBloc);
    $(window).on('scroll resize', handleAnimation);
    $(window).trigger('resize');
});

function handleBloc() {
    const containers = document.querySelectorAll('.bloc, .bloc-cta');

    // Default background type
    let backgroundFound = false;
    let bgType = 'light';

    containers.forEach(container => {
        const bloc = $(container);
        const blocTop = bloc.offset().top;
        const limiterClass = bloc.find('.limiter, .limiter-max').attr('class');
        const enteredBlocClass = bloc.attr('class');

        if (blocTop <= window.scrollY && (blocTop + bloc.height()) > window.scrollY) {
            if (limiterClass.includes('bg-')) {
                bgType = limiterClass.includes('bg-gris') || limiterClass.includes('bg-noir') || limiterClass.includes('bg-main') || limiterClass.includes('bg-ca') ? 'dark' : 'light';
            } else if (enteredBlocClass.includes('bg-')) {
                bgType = enteredBlocClass.includes('bg-gris') || enteredBlocClass.includes('bg-noir') || enteredBlocClass.includes('bg-main') || enteredBlocClass.includes('bg-ca') ? 'dark' : 'light';
            } else {
                bgType = 'light';
            }
        }
    });
    $('body').attr('data-bg', bgType);
}

function handleAnimation() {

    jQuery('.has-animate, .bloc, .bloc-cta').each(function () {
        var animatedElem = jQuery(this).offset().top;
        var windowHeight = $(window).height();
        var scroll = $(window).scrollTop();

        if (scroll + windowHeight > animatedElem) {
            jQuery(this).addClass('animate');
        } else {
            jQuery(this).removeClass('animate');
        }
    });
}


// =============================================================================
// Custom Functions
// =============================================================================
const parseKeyValuesString = (keyValuesString) => {
    const regex = /\[(.*?)\]/;
    const match = keyValuesString.match(regex);

    if (!match) {
        return null;
    }

    const keyValuePairs = match[1].split(',').map(pair => {
        const [key, value] = pair.split(':').map(item => item.trim());
        return {[key]: parseFloat(value) || value};
    });

    const mergedObject = {};

    keyValuePairs.forEach(pair => {
        $.extend(mergedObject, pair);
    });

    return mergedObject;
};

const mergeObjects = (obj1, obj2) => {
    const result = $.extend({}, obj1);
    for (const key in obj2) {
        if (obj2.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
            result[key] = obj2[key];
        }
    }
    return result;
};

// =============================================================================
// GSap Functions
// =============================================================================
jQuery(document).on('click', '[click-gsap]', function () {

    var baseAnimation = {
        duration: '.7',
        ease: Power2.easeOut,
    };

    var data = jQuery(this).attr('click-gsap');

    var [target, anim1, anim2] = data.split('|');
    var animateOpen = parseKeyValuesString(anim1);
    var animateClose = parseKeyValuesString(anim2);
    
    target = target.trim();
    animateOpen = mergeObjects(animateOpen, baseAnimation);
    animateClose = mergeObjects(animateClose, baseAnimation);

    if (!jQuery(this).closest('[click-gsap-parent="opened"]').length) {
        // Do open
        gsap.to(target, animateOpen);

        jQuery(this).parents('div').attr('click-gsap-parent', 'opened');
    } else {
        // Do close
        gsap.to(target, animateClose);

        jQuery(this).parents('div').attr('click-gsap-parent', '');
    }
});

function gsap_reveal() {

    jQuery('[gsap]').each(function () {

        gsap.fromTo(jQuery(this), {
            x: jQuery(this).attr('gsap-x') || 0,
            y: jQuery(this).attr('gsap-y') || 0,
            opacity: jQuery(this).attr('gsap-o') || 1,
        }, {
            duration: jQuery(this).attr('gsap-dur') || '.7',
            delay: jQuery(this).attr('gsap-delay') || '0',
            x: 0,
            y: 0,
            opacity: 1,
            stagger: jQuery(this).attr('gsap-stagger') || '0',
            ease: jQuery(this).attr('gsap-ease') || Power2.easeOut,
            scrollTrigger: {
                trigger: jQuery(this).closest('[gsap-wrapper]'),
                start: jQuery(this).attr('gsap-start') || '0 65%',
                end: jQuery(this).attr('gsap-end'),
                scrub: jQuery(this).attr('gsap-scrub'),
                toggleActions: jQuery(this).attr('gsap-actions') || "play none none none",
                // markers: true
            }
        });
    });

    jQuery('.-gsap').each(function () {
        var result = get_classesToKeyValue(jQuery(this), '-gsap-');

        gsap.fromTo(jQuery(this), {
            x: result['-gsap-x'] || 0,
            y: result['-gsap-y'] || 0,
            opacity: result['-gsap-o'] || 1,
        }, {
            duration: result['-gsap-dur'] || '.7',
            delay: result['-gsap-delay'] || '0',
            x: 0,
            y: 0,
            opacity: 1,
            stagger: result['-gsap-stagger'] || '0',
            ease: result['-gsap-ease'] || Power2.easeOut,
            scrollTrigger: {
                trigger: jQuery(this).closest('[gsap-wrapper]'),
                start: result['-gsap-start'] || '0 65%',
                end: result['-gsap-end'],
                scrub: result['-gsap-scrub'],
                toggleActions: result['-gsap-actions'] || "play none none none",
                // markers: true
            }
        });
    });
}

function gsap_title() {

    jQuery('[gsap-title]').each(function () {

        var gsap_title = gsap.timeline({
            scrollTrigger: {
                trigger: jQuery(this).closest('[gsap-wrapper]'),
                start: '0 70%',
                toggleActions: "play none none reset",
            }
        });

        gsap_title.set(jQuery(this).find('.title-decor'), {
            width: '0%',
        });
        gsap_title.set(jQuery(this).find('.title-inner'), {
            y: '120%',
        });

        gsap_title.to(jQuery(this).find('.title-decor'), {
            duration: '.7',
            width: '100%',
        });
        gsap_title.to(jQuery(this).find('.title-inner'), {
            duration: '.7',
            y: '0',
        }, '-=.3');
    });
}

function gsap_number() {

    if (jQuery('[gsap-number]').length) {

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function () {

                jQuery('[gsap-number]').each(function () {

                    var gsap_number = gsap.timeline({
                        scrollTrigger: {
                            trigger: jQuery(this).closest('[gsap-wrapper]'),
                            start: '0 70%',
                            toggleActions: "play none none none",
                        }
                    });

                    gsap_number.from(jQuery(this), {
                        textContent: 0,
                        duration: 1.5,
                        ease: Power2.easeIn,
                        snap: {textContent: 1},
                        stagger: 1,
                    });
                });

            }
        });
    }

    if (jQuery('.-gsapnumbers').length) {
        jQuery('.-gsapnumbers').each(function () {
            if (jQuery(this).html() !== '0') {
                jQuery(this).attr('data-num', jQuery(this).html());

                var gsap_number = gsap.timeline({
                    scrollTrigger: {
                        trigger: jQuery(this).closest('[gsap-wrapper]'),
                        start: '0 70%',
                        toggleActions: "play none none none",
                    }
                });

                gsap_number.from(jQuery(this), {
                    textContent: 0,
                    duration: 1.5,
                    ease: Power2.easeIn,
                    snap: {textContent: 1},
                    stagger: 1,
                });
            }
        });
    }
}

function gsap_parallax() {

    jQuery('.embed-responsive-parallax .js-background').each(function () {

        var effect = 15;

        gsap.set(jQuery(this), {
            height: ((2 * effect) + 100) + '%',
        });

        var gsap_parallax = gsap.timeline({

            scrollTrigger: {
                trigger: jQuery(this).closest('[gsap-wrapper]'),
                start: '0% 50%',
                end: '100% 50%',
                scrub: true,
                invalidateOnRefresh: true,
            }
        });

        gsap_parallax.fromTo(jQuery(this), {
            top: '-' + effect + '%',
            ease: 'none'
        }, {
            top: '' + effect + '%',
            ease: 'none'
        });
    });

    jQuery('[gsap-react-scroll]').each(function () {

        if (jQuery(this).attr('gsap-react-scroll') === '') {
            var effect = ['-50', '50'];
        } else {
            var effect = jQuery(this).attr('gsap-react-scroll').split(',');
        }

        gsap.fromTo(jQuery(this), {
            y: effect[0],
        }, {
            y: effect[1],
            ease: "none",
            scrollTrigger: {
                trigger: jQuery(this).parents('.bloc'),
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
                invalidateOnRefresh: true, // to make it responsive
            }
        });
    });
}

function gsap_sticky_content() {

    if (jQuery('body').find('[sticky-content]').length) {
        setTimeout(function () {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": function () {

                    gsap.to('[sticky-container]', {

                        scrollTrigger: {
                            trigger: '[sticky-content]',
                            pin: true,
                            start: 'top top',
                            end: 'bottom 50%',
                            endTrigger: '[sticky-container]',
                            invalidateOnRefresh: true,
                        },
                        ease: "none"
                    });
                }
            });
        }, 500);
    }
}

function get_classesToKeyValue(selector, findKeys) {
    var gsapClasses = {};

    // $(selector).each(function () {
    var classes = selector.attr('class').split(' ');

    classes.forEach(function (className) {
        if (className.startsWith(findKeys)) {
            // Extract key and value from the class name
            var parts = className.split('-').slice(2); // Remove '.-gsap-' and split by hyphen
            var key = findKeys + parts.slice(0, -1).join('-'); // Join parts except the last one
            var value = parts.slice(-1)[0]; // Take the last part

            gsapClasses[key] = value;
        }
    });
    // });
    return gsapClasses;
}

jQuery(document).mousemove(function (event) {

    jQuery(this).find('[gsap-mouse-react]').each(function () {
        var shiftValue = jQuery(this).attr('data-shift'),
                moveX = (event.clientX * shiftValue) / 250,
                moveY = (event.clientY * shiftValue) / 250;

        gsap.to(jQuery(this), {
            x: moveX,
            y: moveY,
            duration: 1
        });
    });
});

jQuery(document).on('mousemove', '[gsap-mouse-react-container]', function (event) {

    jQuery(this).find('[gsap-mouse-react-contained]').each(function () {
        var shiftValue = jQuery(this).attr('data-shift'),
                moveX = (event.pageX * shiftValue) / 250,
                moveY = (event.pageY * shiftValue) / 250;

        gsap.to(jQuery(this), {
            x: moveX,
            y: moveY,
            // duration: 1 
        });
    });
});



jQuery(document).ready(function () {

    gsap_reveal();

    gsap_number();

    gsap_parallax();
    gsap_sticky_content();

});

jQuery(window).on('resize', function () {

    gsap_reveal();

    gsap_parallax();
    gsap_sticky_content();

});



// =============================================================================
// Prestashop
// =============================================================================
gsap.set(jQuery('.facet .facet-list li'), {
    y: -10,
    opacity: 0,
});

jQuery(document).on('click', '.facet .facet-title.trigger', function () {

    jQuery(this).parents('.facet').toggleClass('open');

    if (jQuery(this).parents('.facet').hasClass('open')) {
        gsap.to(jQuery(this).parents('.facet').find('.facet-list'), {
            maxHeight: 320,
            duration: .7,
            ease: Power2.easeOut,
        });
        gsap.to(jQuery(this).parents('.facet').find('.facet-list li'), {
            y: 0,
            opacity: 1,
            stagger: .2,
            ease: Power2.easeOut,
        });
    } else {
        gsap.to(jQuery(this).parents('.facet').find('.facet-list'), {
            maxHeight: 0,
            duration: .7,
            ease: Power2.easeOut,
        });
        gsap.to(jQuery(this).parents('.facet').find('.facet-list li'), {
            y: -10,
            opacity: 0,
            stagger: .2,
            ease: Power2.easeOut,
        });
    }

});


// =============================================================================
// Iro
// =============================================================================
import iro from '@jaames/iro';

/*
 *  <div class="ColorPicker" id="sliderPicker">
 <h3>"It's all sliders!"</h3>
 </div>
 */

if (jQuery('#sliderPicker').length) {

    var sliderPicker = new iro.ColorPicker("#sliderPicker", {
        width: 246,
        sliderSize: 13,
        color: "rgb(255, 0, 0)",
        saturation: 30,
        value: 90,
        borderWidth: 0,
        borderColor: "#fff",
        layout: [
            {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'hue'
                }
            },
            {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'saturation'
                }
            },
            {
                component: iro.ui.Slider,
                options: {
                    sliderType: 'value'
                }
            },
                    /*{
                     component: iro.ui.Slider,
                     options: {
                     sliderType: 'alpha'
                     }
                     },*/
        ]
    });

    sliderPicker.on('color:change', function (color) {
        console.log(color.hexString);

        jQuery('[data-color-react]').css({
            'background-color': color.hexString
        });
    });
}

