import $ from 'jquery';
window.jQuery = $;
window.$ = $;

function updateViewportDimensions() {
    var w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
    return {width: x, height: y};
}

var viewport = updateViewportDimensions();

$.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}



// =============================================================================
// Toolbox
// =============================================================================
jQuery(document).on('click', '.toolbox-trigger', function () {
    jQuery(this).parents('.toolbox-wrapper').toggleClass('active');
});

// =============================================================================
// Question
// =============================================================================
jQuery(document).on('click', '.question-trigger', function () {
    var question = jQuery(this).closest('.question');

    if (question.hasClass('active')) {
        question.removeClass('active');
    } else {
        if (question.hasClass('auto-close')) {
            jQuery(this).closest('.limiter').find('.reading').removeClass('active');
        }
        question.addClass('active');
    }
});


// =============================================================================
// Open This
// =============================================================================
jQuery(document).on('click', '[open-this]', function () {
    var target = jQuery(this).attr('open-this');

    if (jQuery(this).hasClass('open-this')) {
        // close this
        jQuery(this).closest('[open-this-container]').find('[open-this="' + target + '"]').removeClass('open-this');
        jQuery(this).closest('[open-this-container]').find('[open-this-content="' + target + '"]').removeClass('open-this');
    } else {
        // open this
        jQuery(this).closest('[open-this-container]').find('[open-this="' + target + '"]').addClass('open-this');
        jQuery(this).closest('[open-this-container]').find('[open-this-content="' + target + '"]').addClass('open-this');

        /*if (jQuery('[trigger-map]')) {
         jQuery('[trigger-map="' + target + '"]').trigger('click');
         }*/
    }
});

jQuery(document).on('click', '[open-this-one]', function () {
    var target = jQuery(this).attr('open-this-one');

    jQuery(this).closest('[open-this-container]').find('[open-this-one]').removeClass('open-this');
    jQuery(this).closest('[open-this-container]').find('[open-this-content]').removeClass('open-this');

    jQuery(this).closest('[open-this-container]').find('[open-this-one="' + target + '"]').addClass('open-this');
    jQuery(this).closest('[open-this-container]').find('[open-this-content="' + target + '"]').addClass('open-this');

    /*if (jQuery('[trigger-map]')) {
     jQuery('[trigger-map="' + target + '"]').trigger('click');
     }*/
});


// =============================================================================
// js Select
// =============================================================================
jQuery(document).ready(function () {
    jQuery('.js-select').each(function () {
        jQuery(this).addClass('hidden');
        jQuery(this).after('<div class="js-select2 cf">\n\
                                <a href="javascript:;" class="trigger"><i class="icon"></i></a>\n\
                                <ul class="js-select-ul"></ul>\n\
                            </div>');

        jQuery(this).find('option').each(function (index) {
            var data = jQuery(this)[0];
            var first = '';
            if (index == 0) {
                first = 'selected';
            }
            jQuery(this).parents('select').siblings('.js-select2').find('ul').append('<li class="' + first + '"><a href="javascript:;" class="option" data-value="' + data.value + '">' + data.label + '</a></li>');
        });
    });
});

jQuery(document).on('click', '.js-select2 .option', function () {
    var data = jQuery(this)[0];

    // trigger option click
    if (jQuery(this).parents('.js-select2').hasClass('-no-change')) {

    } else {
        jQuery(this).parents('.js-select2').siblings('.js-select').find('option[value="' + data.dataset.value + '"]').prop('selected', true);
    }
    // show selected
    jQuery(this).parents('ul').find('.selected').removeClass('selected');
    jQuery(this).parents('li').addClass('selected');

    jQuery(this).parents('.js-select2').removeClass('open');
});

jQuery(window).click(function () {
    //Hide the menus if visible
    if (jQuery('.js-select2').hasClass('open')) {
        jQuery('.js-select2').removeClass('open');
    }
});
jQuery(document).on('click', '.js-select2', function (event) {
    event.stopPropagation();
});
jQuery(document).on('click', '.js-select2 .trigger', function () {
    jQuery(this).parents('.js-select2').toggleClass('open');
});


// =============================================================================
// Menu with sub indicator
// =============================================================================
jQuery(document).ready(function () {

    gsap_menuindicator_init();
});

function gsap_menuindicator_init() {

    jQuery('.-has-menu-indicator').each(function () {
        jQuery(this).append('<span class="indicator"></span>');
        gsap_menuindicator_update(jQuery(this).find('.active, .open-this'), 'init');
    });
}

function gsap_menuindicator_update(filter, action) {
    var indicator = filter.parents('.-has-menu-indicator').find('.indicator');

    indicator.css({
        'left': filter[0].offsetLeft,
        'width': filter[0].clientWidth,
    });

    if (action === 'init') {
        // init 
    }
    if (action === 'enter') {
        // leave
    }
    if (action === 'leave') {
        // leave
    }
    if (action === 'click') {
        // click a 
    }
}

jQuery(document).on('mouseenter', '.-has-menu-indicator li', function () {
    gsap_menuindicator_update(jQuery(this), 'enter');
});

jQuery(document).on('mouseleave', '.-has-menu-indicator', function () {
    gsap_menuindicator_update(jQuery(this).find('.active, .open-this'), 'leave');
});

jQuery(document).on('click', '.-has-menu-indicator a', function () {
    gsap_menuindicator_update(jQuery(this).parents('li'), 'click');
});

jQuery(window).on('resize', function () {

    jQuery('.-has-menu-indicator').each(function () {
        gsap_menuindicator_update(jQuery(this).find('.active, .open-this'), 'init');
    });
});


// =============================================================================
// Anchor Navigation
// =============================================================================
jQuery(document).ready(function () {

    if (jQuery('body').find('.ANCHOR_navigation').length && jQuery('body').find('.ANCHOR').length) {

        jQuery('.ANCHOR').each(function () {
            var anchor = jQuery(this);
            jQuery('.ANCHOR_navigation ul').append('<li><a href="#' + anchor.attr('id') + '">' + anchor.attr('data-name') + '</a></li>');
        });
    }
});


// =============================================================================
// Shortcode lire
// =============================================================================
jQuery(document).on('click', '.short-lire-btn', function () {

    if (jQuery(this).parents('.short-lire-container').hasClass('is-reading')) {

        jQuery(this).parents('.short-lire-container').toggleClass('is-reading');

        if (viewport.width < 768) {

            jQuery('html,body').animate({
                scrollTop: jQuery(this).parents('.short-lire-container').offset().top - 60
            }, 500);
        } else {

            jQuery('html,body').animate({
                scrollTop: jQuery(this).parents('.bloc').offset().top - 60
            }, 500);
        }
    } else {
        jQuery(this).parents('.short-lire-container').toggleClass('is-reading');
    }
});


// =============================================================================
// js Filter
// =============================================================================
var jsfilter_current = {};
jsFilter_init();

jQuery(document).on('change', '.js-filter select', function () {
    var dataKey = jQuery(this).attr('name');
    var dataName = jQuery(this).val();

    if (dataName == 'all') {
        delete jsfilter_current[dataKey];
    } else {
        jsfilter_current[dataKey] = dataName;
    }

    jsFilters_update();
    jsFilters_count();
});

jQuery(document).on('click', '.js-filter a', function () {
    var dataKey = jQuery(this).parents('ul').attr('data-name');
    var dataName = jQuery(this).attr('data-value');

    jQuery(this).parents('ul').find('.active').removeClass('active');
    jQuery(this).parents('li').addClass('active');

    if (dataName == 'all') {
        delete jsfilter_current[dataKey];
    } else {
        jsfilter_current[dataKey] = dataName;
    }

    jsFilters_update();
    jsFilters_count();
});

jQuery(document).on('click', '.js-select-reset a', function () {

    jsFilters_remove();
});

function jsFilter_init() {
    jQuery('.js-filter select').each(function () {
        var dataKey = jQuery(this).attr('name');
        var dataName = jQuery(this).val();

        if (dataName == 'all') {
            delete jsfilter_current[dataKey];
        } else {
            jsfilter_current[dataKey] = dataName;
        }
    });
    jQuery('.js-filter a').each(function () {
        var dataKey = jQuery(this).parents('ul').attr('data-name');
        var dataName = jQuery(this).attr('data-value');

        if (dataName == 'all') {
            delete jsfilter_current[dataKey];
        } else {
            jsfilter_current[dataKey] = dataName;
        }
    });

    jsFilters_update();
    jsFilters_count();
}

function jsFilters_update() {

    jQuery('.js-content').each(function () {
        var jsContent = jQuery(this);

        if (!jQuery.isEmptyObject(jsfilter_current)) {
            $.each(jsfilter_current, function (key, value) {
                var compare = jsContent.data(key);

                if (compare.includes(value) === true) {
                    jsContent.removeClass('hidden');
                } else if (compare === value) {
                    jsContent.removeClass('hidden');
                } else {
                    jsContent.addClass('hidden');
                    return false;
                }
            });
        } else {
            jsContent.removeClass('hidden');
        }
    });
}

function jsFilters_count() {
    var total = jQuery('.js-content:not(.hidden)').length;
    var message_empty = "Toutes les pièces peuvent être commandées. N'hésitez pas à nous envoyer votre demande à l'aide du formulaire ci-après. Nous reviendrons vers vous dans les meilleurs délais.";

    jQuery('.js-filter-count .num').html(total);

    jQuery('.js-content-meta').remove();

    if (total === 0) {
        jQuery('.js-content').parent().append('<div class="col-12 js-content-meta -empty cf">' + message_empty + '</div>');
    } else if (total === 1) {
        jQuery('.js-filter-count .plural').addClass('hidden');
    } else {
        jQuery('.js-filter-count .plural').removeClass('hidden');
    }
}

function jsFilters_remove() {
    jQuery('.js-content').removeClass('hidden');

    $.each(jsfilter_current, function (key, value) {
        jQuery('select[name="' + key + '"] option:eq(0)').prop('selected', true).change();
        jQuery('ul[data-name="' + key + '"] li').first().trigger('click');
    });
}

jQuery(document).ready(function () {

    if ($.urlParam('categorie')) {
        jQuery('.js-filter [data-name="categorie"] [data-value="' + $.urlParam('categorie') + '"]').trigger('click');
    }
});


// =============================================================================
// Gallery with thumbs
// =============================================================================
jQuery(document).on('click', '.gallery-thumbs a', function () {
    var thumb = jQuery(this);

    thumb.parents('.gallery-thumbs').find('.active').removeClass('active');
    thumb.addClass('active');

    thumb.parents('.gallery-thumb-wrapper').find('.gallery-image').css({
        'opacity': 0,
        'transform': 'translate(-20px, 0)',
    });

    setTimeout(function () {
        thumb.parents('.gallery-thumb-wrapper').find('.gallery-image .js-background').css({
            'background-image': 'url(' + thumb[0].dataset.url + ')',
            'transform': 'translate(20px, 0)',
        });
        thumb.parents('.gallery-thumb-wrapper').find('.gallery-image').css({
            'opacity': 1,
            'transform': 'translate(0, 0)',
        });
    }, 500);
});